import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/the-most-expensive-cameras-in-the-world",
  "date": "2013-08-12",
  "title": "THE MOST EXPENSIVE CAMERAS IN THE WORLD",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "Digital photography has become a hobby that is more and more affordable in recent years, with the increase of hobbyist photographers to serious enthusiasts the camera and lens manufacturers have become competitive and innovative in releasing high specification products."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This also has led to targeting products to the luxury and prestige market. The cameras we are discussing here are not particulary exceptional when it comes to value for money, but it is more you pay for the brand than the value. Here we are discussing the most expensive cameras in the world.`}</p>
    <h2>{`Hasselblad H4D-200MS`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/hasselblad_h4d-200ms.jpeg",
        "alt": "Hasselblad H4D-200MS"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $35,995.00`}</p>
    <p>{`The Hasselblad H4D-200MS Medium Format DSLR Camera** must be the top of them all. Built on the 50 MP  H4D-50MS model, with multi-shot capture technology which renders a high-resolution monster 200MP photos. This camera takes 6 shots to produce extraordinary high-quality images.`}</p>
    <p>{`The 50MP sensor is mounted onto the Hasselblad patented symmetrical multi-shot frame, which positions the sensor with sub-micron accuracy using piezo-electrical actuators. Bayer-patterned pixels are used to create the 200MP capture.`}</p>
    <p>{`The H4D-200MS also captures in normal single-shot, at 50MP for live subjects, and in multi-shot capture for 50MP, with 4 shots, for increased detail of still subjects. It features a large, 3" (7.6 cm) TFT LCD with 24-bit color and a 230,400-pixel resolution.`}</p>
    <h2>{`Mamiya Leaf Credo 80MP Digital SLR Camera Kit`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/mamiya_leaf_body-fsl_lens-102968.png",
        "alt": "Mamiya Leaf"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $37,995.00`}</p>
    <p>{`Targeted to the high-end market, Mamiya 645DF+ camera combined with Leaf Credo backs offers an enhanced auto-focus mechanism delivering accurately focused images, even when shooting in low light or low contrast environments.`}</p>
    <p>{`To deal with focus critical captures in difficult environments, an all-new custom adjustment feature was introduced to fine tune the alignment of the camera body to its digital back.
All new high-powered rechargeable Li-ion battery, enables up to 10,000 captures on a single charge. You could use Mamiya digital focal plane lenses, Hasselblad V lenses, or Schneider-Kreuznach leaf shutter lenses.`}</p>
    <h2>{`Leica S2-P Camera`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/ad8e0fb2-1953-4e5b-9a1f-9f387ff6c11b.jpg",
        "alt": "Leica S2-P"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $30,000`}</p>
    <p>{`Popular among professional photographers, the Leica S2-P model targeted for those who are interested in outdoor photography. With 37.5 MP 30x45 image sensor, it takes S-System lenses. It is waterproof and dustproof with a scratch resistant Sapphire LCD Cover, and the battery has an extremely long life.`}</p>
    <p>{`It offers excellent imaging quality along with the usual Leica outputs of sharp and clear color in the pictures, this model has indeed become the camera of choice for those on the road.`}</p>
    <h2>{`Pentax 645D`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/pentax_645d_lens-fsr-57991.png",
        "alt": "Pentax 645D"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $8799.95`}</p>
    <p>{`Depending on the type of photographer you are, the Pentax 645Dcould be a dream camera. Not ideal for event photographers though, as the 40-megapixel camera isn't a high ISO monster, can't record video, and doesn't fire off shots in rapid succession. But if you're into studio portraits or landscapes, the weather-sealed 645D  may be right camera for you.`}</p>
    <p>{`It represents a relatively inexpensive path into medium-format digital photography, as the similar Hasselblad H4D-40 and the Leica S2 are relatively highly expensive.`}</p>
    <h2>{`Nikon D3X`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/nikon_d3x_cad.png",
        "alt": "Nikon D3X"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $7,999.95`}</p>
    <p>{`Nikon D3X is a professional-grade full-frame camera with** **a 24.5-megapixel, FX-format (35.9 x 24.0mm) CMOS sensor , and it's pricier than D4  the latest of the professional-grade camera which only has a resolution of 16.2 megapixels, but only 5 frames per second, instead of 's 11 fps, but has a longer battery life of 4400 shots.`}</p>
    <p>{`It offers 100% viewfinder coverage and a tough and durable alloy body`}<strong parentName="p">{`.`}</strong>{` It features two CF card slots.`}</p>
    <h2>{`Canon EOS-1D X`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-12-the-most-expensive-cameras-in-the-world/Elegant-Canon-Eos-1dx-Lens-Wallpaper.png",
        "alt": "Canon EOS"
      }}></img></p>
    <p><strong parentName="p">{`Price`}</strong>{` $6,799`}</p>
    <p>{`Canon’s new flagship in the Pro DSLR range, the Canon EOS1Dx with an 18 megapixel Full-Frame CMOS sensor, 61 Autofocus points and dual DIG!C 5 Processors. This means high-resolution images with extremely fast shooting capable of 12 frames per second as well as Full HD Video for 29 minutes and 59 seconds.`}</p>
    <p>{`The 1Dx also has 2 CF card slots, which can be used to store your data in several different ways; as overflow, one for RAW and one for JPEG, or one for Photos and one for Video.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      